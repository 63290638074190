<template>
	<section id="stats">
		<v-parallax
			:height="$vuetify.breakpoint.smAndDown ? 700 : 500"
			src="@/assets/paralax-image.jpg"
		>
			<v-container fill-height>
				<v-row class="mx-auto">
					<v-col
						v-for="[value, title] of stats"
						:key="title"
						cols="12"
						md="3"
						class="mr-6 mb-6"
					>
						<div class="text-center">
							<div
								class="secondary--text text-shadow display-3 font-weight-black mb-4"
								v-text="value"
							></div>

							<div
								class="title secondary--text text-shadow font-weight-bold text-uppercase"
								v-text="title"
							></div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-parallax>
	</section>
</template>

<script>
export default {
	name: 'AppStats',

	data() {
		return {
			//
		};
	},
	computed: {
		stats() {
			const stats = [
				['1k+', this.$t('dailyVisits')],
				['100+', this.$t('installedOnPhone')],
				['10+', this.$t('articlesInBlog')],
			];

			return stats;
		},
	},
};
</script>

<style lang="scss" scoped></style>
